@import './library.scss';

#print_list_wrapper {
    margin: 0;
    padding: 0;
    background-image: $background-radial;
    color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-bottom: 6rem;
    display: none;

    #printable_attendance_sheet {
        max-width: 816px;
        width: 816px;
        margin: auto;
        background-color: white;
        color: black;
        padding-bottom: 4rem;
        margin-bottom: 2rem;
        margin-top: 4rem;

        .list_name {
            @include header-small;
            padding: 0;
            margin-top: 0;
            margin-bottom: 0;
        }

        #list_table_wrapper {
            @include table-container;
            box-shadow: none;
            border: solid 1px black;
            border-right: solid 1px black;
            max-width: 700px;

            table {
                @include list-table;
                border: none;

                th {
                    @include list-table-header;
                    border-color: black;
                    padding-top: .5rem;
                    @include paragraph-medium;
                }

                td {
                    @include list-table-data;
                    border-color: black;
                    @include paragraph-small;
                    padding-top: 8px;
                }

                .checkbox_div {
                    width: 14px;
                    height: 14px;
                    border: solid 1px black;
                    border-right: solid 2px black;
                    border-bottom: solid 2px black;
                    background-color: white;
                    margin-left: 28px;
                }

                tr {
                    @include list-table-row;
                    border-color: black;
                }
            }
        }
    }
}