@import "./library.scss";
.header_wrapper {
    @include large-header;
    color: white;
}

#content_wrapper {
    @include shadow-form;
    padding-left: 0;
    padding-right: 0;
    column-gap: 2rem;
    row-gap: 1rem;
    margin-top: 6rem;
    color: white;
    width: 80vw;
    margin-bottom: 6rem;
    row-gap: 0;
    .header_wrapper {
        width: 80vw;
        max-width: 560px;
        margin-top: -2rem;
        @include paragraph-large;
        border-radius: 16px 16px 0 0;
    }
}

@media (max-width: 767px) {
    #content_wrapper {
        width: 90vw;
        .header_wrapper {
            width: 90vw;
        }
    }
}