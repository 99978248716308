@import "./library.scss";

#new_applicant_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #333333;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    color: white;
    overflow: hidden;

    .section_heading {
        @include medium-section-heading;
        box-shadow: none; 
        border: none;
    }

    .section_heading:nth-of-type(1) {
        margin-top: 0;
    }
}


@media (max-width: 1023px) {
    #new_applicant_wrapper {
        .section_heading {
            width: 80%;
        }
    }
}