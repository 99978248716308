@import "library.scss";
#no_entries_wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    h2 {
        @include paragraph-large;
        margin-top: 4rem;
        text-align: center;
        color: white;
    }
}

#check_in_wrapper {
    margin: 0;
    padding: 0;
    color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    form {
        @include table-container;
        margin-top: 0;
        height: 100%;
        max-height: 60vh;
        overflow-y: scroll;
        overflow-x: hidden;
        table {
            @include list-table;
            th {
                @include list-table-header;
                border: none;
            }
            td {
                @include list-table-data;
                border: none;
            }
            tr {
                @include list-table-row;
            }
            #attended {
                width: auto;
            }
            a {
                color: $chapel-purple;
            }
            .remove_button {
                @include orange-cancel-button;
            }
            .call_button {
                @include btn-small;
                text-decoration: none;
            }
            .incomplete_data {
                color: $orange;
                &:hover {
                    color: $faded-orange;
                    cursor: pointer;
                }
            }
            .not_present_button {
                @include btn_small;
                border-radius: 16px;
                margin-top: 0;
                min-width: 75px;
                width: 75px;
                @include paragraph-large;
                background-color: $orange;
                &:hover {
                    background-color: $faded-orange;
                }
            }
            .present_button {
                @include btn_small;
                margin-top: 0;
                min-width: 75px;
                width: 75px;
                @include paragraph-large;
            }
            td:last-of-type {
                border-right: none;
            }
            .checked_in_data {
                border-right: none;
            }
            th:last-of-type {
                border-right: none;
            }
            tr:last-of-type {
                border-bottom: none;
            }
        }
    }
}

@media (max-width: 1024px) {
    #check_in_wrapper {
        form {
            width: 95%;
            margin: auto;
            margin-top: 0;
            table {
                width: 100%;
                th {
                    @include paragraph-small;
                    border: none;
                }
                td {
                    @include paragraph-small;
                    border: none;
                }
                #attended {
                    width: 40px;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    #check_in_wrapper {
        form {
            table {
                width: 100%;
                .present_button,
                .not_present_button {
                    min-width: unset;
                    width: unset;
                    padding: 0.25rem 1rem 0.25rem 1rem;
                }
                .call_button {
                    border-radius: 16px;
                    min-width: unset;
                    width: unset;
                    margin-top: 2rem;
                    padding: 0.25rem 1rem 0.25rem 1rem;
                    cursor: pointer;
                    @include paragraph-small;
                    color: white;
                    text-decoration: none;
                    background-color: $purple;
                    &:hover {
                        box-shadow: 0 1px 4px $light-gray;
                        filter: brightness(90%);
                    }
                }
            }
        }
    }
}