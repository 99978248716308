@import "./library.scss";

#past_lists-wrapper {
    overflow: hidden;

    .header_wrapper {
        @include large-header;
        color: white;
    }

    #display_list {
        width: 960px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 2rem;
        padding-bottom: 6rem;
        margin: auto;

        #table_heading {
            text-align: center;
            @include paragraph-large;
            color: white;
        }

        table {
            @include table-container;
            @include list-table;
            color: white;

            th {
                @include list-table-header;

            }

            td {
                @include list-table-data;
                padding-top: 0;
                padding-bottom: 0;
            }

            tr {
                @include list-table-row;
            }

            td:last-of-type {
                border-right: none;
            }

            th:last-of-type {
                border-right: none;
            }

            tr:last-of-type {
                border-bottom: none;
            }

            .phone_link {
                @include btn-small;
                text-decoration: none;
            }

            .positive_symbol {
                @include paragraph-large;
                width: 75px;
                border-radius: 16px;
                padding-top: .15rem;
                padding-bottom: .15rem;
                background-color: $chapel-purple;
                text-align: center;
            }

            .negative_symbol {
                @include paragraph-large;
                width: 75px;
                padding-top: .15rem;
                padding-bottom: .15rem;
                border-radius: 16px;
                background-color: $orange;
                text-align: center;
            }
        }
    }

    #delete_table_button {
        @include btn-small;
        width: 150px;
        margin: 2rem auto auto auto;
    }
}


@media (max-width: 1024px) {
    #past_lists-wrapper {
        #display_list {
            width: 95%;
            margin: auto;

            table {
                width: 100%;

                th {
                    @include paragraph-small;
                    border: none;
                }

                td {
                    @include paragraph-small;
                    border: none;
                }
            }
        }
    }
}