@import "./library.scss";

.printed_input_pair {
    display: flex;
    flex-direction: column;
    color: #002579;
    font-size: 12px;
    margin-top: -12px;
    margin-bottom: 12px;

    .all_values {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        line-height: 1px;
        padding-bottom: 0;
        width: 275px;
    }

    .value {
        margin-bottom: 6px;
        font-size: 14px;
    }

    .all_labels {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-top: solid 2px #002579;
        width: 275px;
    }

    .label {
        line-height: 14px;
        margin-top: 0;
    }
}