@import "./library.scss";

#homepage_wrapper {
  color: white;
  height: 100vh;
  margin: 0;
  padding: 0;
  top: 0;
  background-image: $background-radial;
}

#header_wrapper {
  @include large-header;

}