@import "./library.scss";

#login_container {
    background-image: $background-radial;
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    margin: 0;
    top: 0;
    padding: 0;
    bottom: 0;

    form {
        display: flex;
        flex-direction: column;
        width: 40vw;
        padding: 2rem 8rem 2rem 8rem;
        margin: auto;
        background-color: $chapel-gray;
        justify-content: center;
        box-shadow: 0 4px 12px rgba(0, 0, 0, .2);
        border-radius: 4px;
        max-width: 560px;

        label {
            @include medium-label;
        }

        input {
            @include medium-input;
        }

        .submit_button {
            @include btn-small;
            width: 150px;
            margin: 2rem auto auto auto;
        }
    }
}


@media (max-width: 1024px) {
    #login_container {
        form {
            width: 80vw;
            padding-left: 1.5rem;
            padding-right: 1.5rem;

            label {
                margin-left: 1.25%;
            }

        }

        input {
            width: 95%;
            margin: auto;
        }

        .submit_button {
            @include btn-small;
            width: 150px;
        }
    }
}

@media (max-width: 767px) {

    #login_container {
        align-content: flex-start;

        form {
            height: 250px;
            margin-top: 6rem;

            .submit_button {
                margin: 1rem auto auto;
            }
        }
    }
}