@import "./library.scss";

#cl_edit_module_overlay {
    @include dark-overlay;
}

#cl_edit_module_wrapper {
    @include shadow-form;
    position: fixed;
    z-index: 10;
    top: 10%;
    left: 50%;
    padding: 4rem 2rem 4rem 2rem;
    transform: translate(-50%, 0);
    z-index: 11;

    #cl_edit_module_questions {
        h3 {
            @include paragraph-large;
            color: $white;
            text-align: center;
        }
    }

    #cl_edit_module_addNew {
        position: fixed;
        top: -10rem;
        bottom: 0;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100vh;
        z-index: 12;
        background-color: $chapel-gray;
        border-radius: 16px;

        #applicant_form {
            margin-top: 1rem;
            padding: 2rem 8rem;
            box-shadow: none;
        }

        #close_form_x {
            @include btn-small;
            @include header-medium;
            min-width: 0;
            padding: .5rem 1rem;
            font-weight: 500;
            position: absolute;
            right: 1rem;
            top: .5rem;
            margin-top: 0;

        }
    }

    #button_wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        column-gap: 2rem;

        button {
            @include btn-small;
            width: 45%;
            ;
        }
    }
}


@media (max-width: 1024px) {
    #cl_edit_module_wrapper {
        @include mobile-shadow-form;
        width: 90%;
        padding-left: 1rem;
        padding-right: 1rem;

        #cl_edit_module_addNew {
            .shadow_form {
                padding-bottom: 30rem;

            }

            #new_applicant_submit {
                margin-bottom: 40rem;
            }

            #close_form_x {
                right: 20%;
            }
        }
    }
}