@import './library.scss';

#fb_list_dashboard {
    #icon_wrapper {
        display: flex;
        justify-content: center;
        column-gap: 4rem;
        row-gap: 1rem;
        margin: 6rem auto auto auto;
        max-width: 560px;
    }
}

@media (max-width: 1023px) {
    #fb_list_dashboard {
        #icon_wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 100vw;
        }
    }
}