@import './library.scss';

#household_income_table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;

    caption {
        font-size: 14px;
        background-color: rgb(255, 204, 153);
    }

    #headings_top_row {
        background-color: rgb(255, 204, 153);
        font-size: 14px;
    }

    #headings_row {
        background-color: rgb(255, 204, 153);

        th {
            font-size: 14px;
            padding-bottom: 4px;
        }
    }

    td {
        width: 20px;
    }

    .small_table_text {
        font-size: 8px;
        font-style: italic;
        font-family: times;
    }

    .circle_family_size {
        border-top: solid 1px #002579;
        border-bottom: solid 1px #002579;
        border-radius: 16px;
    }
}


@media print {
    #household_income_table {
        caption {
            -webkit-print-color-adjust: exact;
            print-color-adjust: exact;
        }

        #headings_top_row {
            -webkit-print-color-adjust: exact;
            print-color-adjust: exact;
        }

        #headings_row {
            -webkit-print-color-adjust: exact;
            print-color-adjust: exact;
        }
    }
}