@import "./library.scss";
#hamburg_wrapper {
    position: fixed;
    left: 1rem;
    top: .3rem;
    border-width: .05rem;
    border-style: solid;
    border-color: white;
    height: 50px;
    width: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background-color: $chapel-gray;
    padding-left: 1rem;
    padding-right: 1rem;
    z-index: 5;
    &:hover {
        background-color: $purple;
    }
}

.hamburger_line {
    width: 2rem;
    height: .15rem;
    background-color: white;
    margin-top: 3px;
    margin-bottom: 3px;
}

@media (min-width: 1023px) {
    #hamburg_wrapper {
        display: none;
    }
}