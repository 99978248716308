@import "./library.scss";

#edit_applicant_wrapper {
    
    h1 {
        @include header-large;
    }

    form {
        @include shadow-form;
    }

    label {
        @include medium-label;
        text-align: left;
    }

    .input_pair {
        width: 100%;
        margin-top: 1.25rem;
        text-align: left;
    }

    input {
        @include medium-input;
        width: 100%;
    }

    .input_button {
        @include btn-small;
        width: 120px;
    }

    .section_heading {
        @include medium-section-heading;
    }
}


@media (max-width: 1023px) {
    #edit_applicant_wrapper {
        form {
            @include mobile-shadow-form;
            margin-bottom: 40rem;
        }

        .input_pair {
            width: 80%;
        }

        .section_heading {
            width: 80%;
        }
    }
}