@import "./library.scss";
body {
    margin: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    background-image: $background-radial;
    overflow-x: hidden;
    overscroll-behavior: none;
}

#root {
    margin: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    background-image: $background-radial;
}

#header_wrapper {
    @include large-header;
    padding-right: 2rem;
    padding-left: 2rem;
}

//Remove the arrows on the number input fields.
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}