@import "../styles/library.scss";
#attendance_sub_heading,
#current_view_heading {
    text-align: center;
    background-color: $orange;
    padding-top: .15rem;
    padding-bottom: .15rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    transition: all .4s ease-in-out;
    p {
        @include paragraph-medium;
        color: white;
        font-size: 16px;
        line-height: 0;
        text-transform: uppercase;
        text-shadow: rgba(0, 0, 0, 0.2) 1px 5px 7px;
    }
}

#current_view_heading {
    background-color: $purple;
}

.sticky_heading {
    position: fixed;
    top: 0;
    left: 0;
}

.non_sticky_header {
    position: relative;
}