@import "./library.scss";

.dashboard_icon {
    @include shadow-form;
    border-style: none;
    color: $white;
    width: 20%;
    @include paragraph-medium;
    margin-top: 0;

    &:hover {
        cursor: pointer;
        box-shadow: 0px 4px 12px $orange;
    }
}

@media (max-width: 1023px) {
    .dashboard_icon {
        width: 30%;
    }
}