@import "./library.scss";

#search_applicant_wrapper {
    color: white;
    overflow: hidden;
    text-align: center;
    padding-bottom: 5rem;

    .header_wrapper {
        @include large-header;
        color: white;
    }

    h2 {
        @include paragraph-large;
        margin-top: 0;
        margin-bottom: 1rem;
    }

    #search_options_wrapper {
        @include shadow-form;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    #choose_applicant_type {
        width: 300px;
        @include paragraph-medium;
        padding: .05rem;

        &:focus {
            outline-color: $orange;
        }
    }
}

#display_applicant_wrapper {
    @include shadow-form;
    justify-content: start;
    align-items: start;
    margin-bottom: 1rem;

    h1 {
        @include paragraph-large;
        margin: auto;
        margin-top: 2rem;
        width: 100%;
        padding-top: 1rem;
        padding-bottom: 1rem;
        margin-bottom: .5rem;
        border-radius: 16px;
    }

    p {
        @include paragraph-small;
        margin-left: auto;
        margin-right: auto;
    }

    .date_altered {
        @include paragraph-medium;
        color: $orange;
    }

}


@media (max-width: 1023px) {
    #search_applicant_wrapper {
        #search_options_wrapper {
            @include mobile-shadow-form;
            label {
                width: 80%;
            }

            select {
                width: 80%;
            }
        }
    }
    #display_applicant_wrapper {
        @include mobile-shadow-form;
    }
}