@import "./library.scss";

#loading_wrapper {
    position: fixed;
    bottom: 40%;
    left: 50%;
    transform: translate(-50%, 0);

    #outer_circle {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-image: linear-gradient(85deg, $purple 60%, white);
        display: flex;
        align-items: center;
        animation-name: spin;
        animation-duration: 1.25s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;


        #inner_circle {
            background: $chapel-gray;
            width: 75px;
            height: 75px;
            border-radius: 50%;
            margin: auto;
        }
    }

    @keyframes spin {
        from {
            transform: rotate(-360deg)
        }

        to {
            transform: rotate(360deg)
        }
    }
}