@import "./library.scss";

#applicantSearch {
    @include shadow-form;
    padding-top: 2rem;
    padding-bottom: 2rem;

    label {
        @include medium-section-heading;
        margin-top: 0;
        margin-bottom: 0;
        color: white;
    }

    select {
        @include medium-input;
        margin-top: 1rem;
        width: 70%;
    }

    #applicantSearchSubmit {
        @include btn-small;
        width: 150px;
    }
}


@media (max-width: 1023px) {
    #applicantSearch {
        @include mobile-shadow-form;

        label {
            width: 80%;
        }

        select {
            width: 80%;
        }
    }
}