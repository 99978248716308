@import "./library.scss";

#past_list_form {
    @include shadow-form;
    padding-top: 2rem;
    padding-bottom: 2rem;

    label {
        @include medium-section-heading;
    }

    select {
        @include medium-input;
        margin-top: 0;
        width: 70%;
    }

    #table_select_submit {
        @include btn-small;
        width: 150px;
    }

    #title_text {
        text-align: center;
        color: white;
        @include paragraph-large;
    }
}


@media (max-width: 1023px) {
    #past_list_form {
        @include mobile-shadow-form;

        label {
            width: 80%;
        }

        select {
            width: 80%;
        }
    }
}