@import "./library.scss";

#edit_delete_wrapper {
    @include btn-multiple;
    margin-bottom: 2rem;
    button {
        @include btn-small;
    }

    .delete_button {
        @include btn-small-danger;
    }
}

@media (max-width: 767px) {
    #edit_delete_wrapper {
       button {
        width: 100px;
        min-width: 100px;
       }

       .delete_button {
        width: 100px;
        min-width: 100px;
       }
    }
}
