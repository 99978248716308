@import './library.scss';
.content_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    column-gap: 2rem;
    row-gap: .25rem;
    flex-wrap: wrap;
    hr {
        height: 2px;
        width: 100%;
        background-color: $chapel-light-gray;
        border-style: none;
    }
    .statistic_header_wrapper {
        width: 100%;
        background-color: $chapel-light-gray;
        padding-top: 1rem;
        padding-bottom: 1rem;
        color: white;
        @include paragraph-large;
        .statistic_type_header {
            text-align: center;
            margin-top: 0;
            margin-bottom: .5rem;
            @include paragraph-large;
        }
    }
    .statistic_wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        column-gap: 2rem;
        row-gap: 2rem;
        padding-bottom: 2rem;
        padding-top: 2rem;
        .statistic_card {
            @include dashboard_icon;
            border-style: none;
            color: white;
            text-align: center;
            margin: 0;
            border-radius: 0;
            justify-content: flex-end;
            row-gap: 1rem;
            height: 100px;
            padding: 0;
            height: 130px;
            width: 150px;
            .card_footer {
                background-color: $chapel-light-gray;
                padding-top: .25rem;
                padding-bottom: .25rem;
                width: 100%;
                color: white;
            }
            .statistic_title {
                @include paragraph-medium;
                margin-top: 0;
                text-align: center;
                margin-bottom: 0px;
            }
            .statistic_data {
                @include paragraph-large;
                font-size: 48px;
                margin-top: .25rem;
                margin-bottom: 0rem;
            }
        }
    }
}