@import "./library.scss";

#footer_wrapper {
    margin-top: 2rem;
    width: 100vw;
    background-image: $purple-linear;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include paragraph-small;
    @include header-font-shadow;
    line-height: 6px;
    padding-bottom: 6px;
    padding-top: 6px;
    color: $white;
}